import {Form, Icon, Input, Tooltip, Button, notification } from 'antd';
import React  from 'react';
import 'antd/dist/antd.css';
import { Card } from 'antd';

const baseUrlApiAuth:string = process.env.REACT_APP_URL_API_AUTH!

interface FormLogin {
    token: string,
    redirection: string,
}
interface IState {
    user: FormLogin,
    form: any,
    history: any,
    match: any,
    ProcessEnv:string
}
const text = <span>Use your login altyor, the same as your windows session</span>;

export default class NormalLoginForm extends React.Component<IState> {
    state = {
      token: '',
      redirection:'',
    }
    urlAuth = ""
   handleSubmit = (e:any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err:any, values:any) => {
      if (!err) {
        if (this.props.match.params.service){
          this.urlAuth = baseUrlApiAuth + '/' + this.props.match.params.service;
        } else {
          this.urlAuth = baseUrlApiAuth;
        }
        console.log("login on url : " + this.urlAuth)
        await fetch (this.urlAuth, {
            method:'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values)
        })
        .then(res => {
            if (res.status === 401 || res.status === 400) {
                this.openNotification("", res.status)
            } else {
                return res.json()
            }
        })
        .then(data => {
          if ( data ) {
            this.setState({
              token:data.token,
              redirection: data.redirection
            })
            this.openNotification(data.token)
          }
        }).catch((error) => {console.log(error)})
      }
    });
  };
  openNotification = (token?: string, status?: number) => {
    let redirectedService:string = ''
    const {service} = this.props.match.params
    if(service === undefined) {
      redirectedService = 'altyor.Solution'
    } else {
      redirectedService =  service
    }
    if (token) {
      notification.open({
      message: 'Successful',
      description:
          'Redirection to '+ redirectedService,
      icon: <Icon type="check" style={{ color: '#108ee9' }} />,
    });
    setTimeout(() => {
             window.location.href = `${this.state.redirection}/?token=${this.state.token}`;
        },3000);
    } else {
      if (status === 401) {
        notification.open({
        message: 'Error',
        description:
            'Your Login or Password is Incorrect',
        icon: <Icon type="stop" style={{ color: 'red' }} />,
        }); } else if (status === 400) {
          notification.open({
          message: 'Error',
          description:
              'Altyor service : ' + redirectedService + ' do not exist' ,
          icon: <Icon type="stop" style={{ color: 'red' }} />,});
        } else {
          notification.open({
          message: 'Error',
          description:
              'Unknow error',
          icon: <Icon type="stop" style={{ color: 'red' }} />,});
      }
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div style={{display:'flex', justifyContent:'left', alignItems:'center', height:'100vh'}}>
        <Card
          className='LoginCard'
          cover={<img alt="AltyorLogo" src="./logoAltyor.png" />}
        >
          <Form onSubmit={this.handleSubmit} className="login-form" style={{width:'100%'}}>
              <Form.Item >
                  {getFieldDecorator('username', {
                  rules: [
                      {
                        required: true,
                        message: 'Please input your Username!',
                      }
                  ],
                  })(<Input
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.5)' }} />}
                      type="username"
                      placeholder="Username"
                  />)}
              </Form.Item>
              <Form.Item>
              {getFieldDecorator('password', {
                  rules: [
                      { required: true, message: 'Please input your Password!' },
                  ],
              })(
                  <Input
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.5)' }} />}
                  type="password"
                  placeholder="Password"
                  />,
              )}
              </Form.Item>
              <div style={{display:'flex', justifyContent:'space-between'}}>
                <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                    Log in
                </Button>
                </Form.Item>
                <Tooltip placement="right" title={text}>
                  <Button type="primary">Need help ?</Button>
                </Tooltip>
              </div>
          </Form>
        </Card>
      </div>
  )}
}
